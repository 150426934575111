/* You can add global styles to this file, and also import other style files */

// TODO: REMOVE ALL OLD CODE BELOW!!!!!
@import '_components/_variables';
@import '_components/_mixins';

body {
  background: white;
  margin: 0 !important;
}

// Default scrollbar style
*::-webkit-scrollbar {
  background-color: #fff;
  width: 8px;
  height: 8px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 1px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}

// Form Validation
form.ng-submitted {
  .pnkl-validation-errors {
    display: block;
  }

  input.pnkl-input,
  kendo-combobox.k-combobox,
  kendo-datepicker.k-datepicker,
  kendo-datetimepicker.k-datetimepicker,
  kendo-timepicker.k-timepicker {
    &.ng-invalid {
      border-bottom: 1px solid #f74053;
    }
  }
}

// Kendo Dialog

.k-dialog {
  .k-dialog-titlebar {
    background-color: #eae8e8;
    color: #313131;

    .k-dialog-title {
      font-size: 16px;
      overflow: unset;
    }
  }
}

.reconnect-window kendo-dialog .k-dialog-actions {
  display: none;
}

// Kendo Window

.k-window {
  .k-window-titlebar {
    background-color: $geyser;
    color: #313131;

    .k-window-title {
      font-size: 16px;
      overflow: unset;
    }
  }
}

// Kendo Date Picker AngularJS Styling Fix
.k-widget.k-datepicker.k-header,
.k-widget.k-timepicker.k-header {
  .k-picker-wrap.k-state-default span.k-select {
    background-image: none;
    background-color: transparent;
  }
}

//  Fix for pinnakl grid action buttons conflicting with kendo command buttons
.action-button .k-button.action-icon {
  min-width: 0;
}

input.pnkl-input,
textarea.pnkl-input {
  border: none;
  background: transparent;
  display: block;
  width: 100%;
  padding: 0;
  border-bottom: 1px solid #6e7c8f;

  &.dark {
    color: #fff;
  }
}

input.pnkl-input {
  height: 30px;
}

textarea.pnkl-input {
  resize: none;
}

.pnkl-input.with-border {
  border: 1px solid #c3c7cd;
  border-radius: 8px;
  padding: 12px 16px;
}

// Kendo Datepicker changes to match Pinnakl theme
kendo-datepicker.k-datepicker,
kendo-datetimepicker.k-datetimepicker,
kendo-timepicker.k-timepicker {
  width: 100%;
  background: transparent;
  border-bottom: 1px solid #6e7c8f;
  border-radius: 0;
  color: inherit;

  &:hover {
    background-color: transparent;
  }

  span.k-picker-wrap {
    background: transparent;
    border: 0;

    kendo-dateinput span.k-dateinput-wrap {
      background: transparent;
      border: 0;

      input {
        padding: 0;
      }
    }

    .k-select {
      background: transparent none;
    }
  }

  span.k-picker-wrap.k-state-focused .k-input {
    border: 0;
  }
}

// Add class 'datepicker-dark' to filter datepickers
// E.g. <kendo-datepicker [(value)]="date" class="datepicker-dark">
kendo-datepicker.k-datepicker.datepicker-dark,
kendo-timepicker.k-timepicker.timepicker-dark {
  border-bottom: 1px solid #7e8c9e;

  span.k-icon.k-i-calendar {
    color: #7e8c9e;
  }

  kendo-dateinput.k-dateinput {
    color: #fff;
  }
}

kendo-datepicker.k-datepicker.popup-left-margin-adjust {
  kendo-popup {
    margin-left: -100px;
  }
}

// Kendo Combobox changes to match Pinnakl theme
kendo-combobox.k-combobox {
  border: 0;
  background: transparent;
  border-bottom: 1px solid #6e7c8f;
  border-radius: 0;
  width: 100%;

  &.k-widget.ng-invalid .k-dropdown-wrap {
    color: inherit;
  }

  span.k-dropdown-wrap {
    border: 0;
    background: transparent;
    box-shadow: none;

    input {
      padding: 0;
    }
  }

  span.k-select {
    background: transparent;
  }
}

kendo-combobox.k-combobox:hover span.k-select {
  background: transparent;
}

.text-uppercase kendo-combobox input {
  text-transform: inherit;
}

// Add class 'pnkl-select-dark' to filter comboboxes
// E.g. <pnkl-select [options]="options" [(ngModel)]="selectedOption" class="pnkl-select-dark"></pnkl-select>
.pnkl-select-dark kendo-combobox.k-combobox {
  border-bottom: 1px solid #7e8c9e;

  span.k-dropdown-wrap {
    color: #fff;
  }

  span.k-select {
    color: #7e8c9e;
  }
}

.pnkl-select-dark kendo-multiselect.k-multiselect {
  background-color: transparent;
  border: none;
  border-radius: unset;
  border-bottom: 1px solid #7e8c9e;
  box-shadow: none;

  .k-button {
    background-color: #38485a;
    color: #fff;
    background-image: none;

    span {
      text-transform: uppercase;
      font-weight: 200;
      color: #ffffff;
    }

    .k-i-close {
      color: #fff;
      opacity: 1;
    }
  }

  .k-i-loading {
    background-color: transparent;
  }
}

kendo-grid.k-grid {
  .k-grid-header th.k-header {
    padding: 6px;
  }

  td {
    padding: 2px 6px;
    border-bottom: 1px solid #e4e9f0;

    input.k-textbox {
      margin: 1px 0 4px;
    }
  }
}

.width-13-percent {
  width: 13%;
}

.width-7-percent {
  width: 7%;
}

.width-30-percent {
  width: 30%;
}

.width-10-percent {
  width: 10%;
}

.width-25-percent {
  width: 25%;
}

.width-5-percent {
  width: 5%;
}

.height-8 {
  height: 8rem;
}

.height-4 {
  height: 4rem;
}

.k-grid.fixed-header-grid {
  thead,
  tbody,
  tfoot {
    display: block;
  }

  tbody,
  tfoot {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: auto;

    td:last-child {
      border-right: 1px solid #e4e9f0;
    }
  }
}

.k-grid tr.k-alt {
  background-color: #fff !important;
}

.visibility-hidden {
  visibility: hidden;
}

.overflow-auto {
  overflow: auto;
}

.reporting-filter-height {
  height: calc(100vh - 58px - 12rem);
}

.grid-filter-columns {
  border: 1px solid #0b1017;
  background: #1f2832;
  margin: 1rem;
  height: 47vh;
  overflow-y: auto;

  input {
    color: #7e8c9e;
  }

  .mCustomScrollBox {
    width: 100%;
  }
}

.grid-filter-label {
  position: relative;
  left: -8rem;
  top: 1.5rem;
  width: 35%;
}

.display-block {
  display: block !important;
}

.display-none {
  display: none;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.max-height-80vh {
  max-height: 80vh;
}

.info-container {
  mark {
    background-color: #168eff33;
    padding: 0;
  }
}

.FS20 {
  font-size: 20px !important;
}

.risk-filter {
  right: -16px !important;
  top: 43px !important;
}

.width100 {
  width: 100% !important;
}

.color-grey {
  color: #7e8c9e;
}

.oms-side-panel-border {
  border-left: 2px solid #293542;
}

.request-left {
  width: 65% !important;
  float: left;
  background-color: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  min-height: calc(100% - 105px);
}

.request-right {
  width: 35% !important;
  float: right;
}

.paddingLR0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.oms-grid-action-btn {
  margin-left: -156px !important;
  width: 156px !important;
  top: 3px !important;
}

.padding20 {
  padding: 20px;
}

.ellipse-highlighter {
  background-color: #e7ebf0;
  padding: 3px 5px;
  border-radius: 40px;
  color: rgb(126, 140, 158);
}

.report-card-ul li a.ellipse-highlighter {
  color: rgb(126, 140, 158) !important;
}

.grid-align {
  text-align: right;
}

.white-space-pre-line {
  white-space: pre-line;
}

.border-default {
  border: 1px solid black;
}

.center-aligned-row {
  justify-content: center;
}

.right-aligned-row {
  justify-content: flex-end;
}

.word-break-all {
  word-break: break-all;
}

.color-blue {
  color: $blue !important;
}

// bootstrap latest classes -- remove css below when upgrade from bootstrap alpha to a stable and latest version
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.h-100 {
  height: 100% !important;
}

.font-weight-bold {
  font-weight: bold;
}

.text-capitalize {
  text-transform: capitalize;
}

.px-5px {
  padding-left: 5px;
  padding-right: 5px;
}

// End of bootstrap latest classes -- remove css above when upgrade from bootstrap alpha to a stable and latest version

// for cash-flow only, need to make separately in shared
.cash-flow-grid {
  height: 800px;
}

.z-index-10 {
  z-index: 10;
}

.FS18 {
  font-size: 18px !important;
}

.right-align {
  text-align: right;
}

.text-upper-case {
  text-transform: uppercase;
}

.layout-align-center {
  display: flex;
  align-items: center;
}

.background-color-white {
  background-color: #fff;
}

.horizontal-align-center {
  display: flex;
  justify-content: center;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.font-size-12px {
  font-size: 12px;
}

.font-size-14px {
  font-size: 14px;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.padding-top-2rem {
  padding-top: 2rem;
}

.direction-tag {
  border-radius: 10px;
  background-color: #d9dde1;
  padding: 2px 10px;
}

.bg-color-light-blue {
  background-color: lightblue !important;
}

.bottom-border-blue {
  border-bottom: blue solid 1px;
}

kendo-window .k-window-content {
  border: 1px solid #dce2e8;
}

.custom-scroll-light {
  .optiscroll-vtrack,
  .optiscroll-htrack {
    background: rgba(247, 245, 245, 0.3);
  }
}

.margin-top-5px {
  margin-top: 5px;
}

.dropdown-content-align,
.dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  display: unset !important;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 1rem;
  color: #373a3c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.password-change-dropdown-align,
.notification-dropdown-align {
  left: -90px;
  top: 60px;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.upload-button {
  height: 35px;
}

.tree-map-category-label {
  margin-top: -3px !important;
}

.pnl-yearly-grid {
  width: 100%;
  height: 750px;
}

.contact-bulk-upload-grid {
  height: 600px;
}

.cell-wrap-text {
  white-space: normal !important;
}

.crm-contacts-upload-container .page-col-slider-container {
  height: 100%;
}

.tracking-link-file-container {
  .pnkl-validation-errors {
    display: block !important;
  }
}

.investor-returns-grid {
  height: 450px;
}

.target-funds-grid {
  height: 335px;
}

.payment-history-grid {
  max-height: 45vh;
  overflow-y: auto;
}

.communications-all-investors {
  .section-container {
    height: 50vh !important;
  }

  .communication-content-container {
    background: #f1f1f1 !important;
  }
}

.investor-search-remove {
  position: absolute;
  right: 15px;
  top: 20px;
}

.k-switch-handle {
  height: 2em !important;
}

.k-switch-container {
  border: 1px solid #f2f2f2;
}

.pointer {
  cursor: pointer;
}

// Global styles for contact cards
.contacts-cards {
  &:nth-of-type(n) .contact-initials .inner {
    @include contact-initials(#8b1dff);
  }

  &:nth-of-type(2n) .contact-initials .inner {
    @include contact-initials(#ff8816);
  }

  &:nth-of-type(3n) .contact-initials .inner {
    @include contact-initials(#284392);
  }

  &:nth-of-type(4n) .contact-initials .inner {
    @include contact-initials($denim);
  }

  &:nth-of-type(5n) .contact-initials .inner {
    @include contact-initials(#e456a3);
  }

  &:nth-of-type(6n) .contact-initials .inner {
    @include contact-initials(#e456a3);
  }
}

.newDesignModal .k-dialog {
  border-radius: 8px;
}

// TODO unify sidenav styles with other apps
.aside-menu-holder {
  overflow: hidden;
}
